<template>
  <div class="">
    <div class="form-row">
      <sgv-input-file
        class="col-12"
        ref="fileUpload"
        label="ไฟล์ CSV"
        @change="changeToJSON($event)">
      </sgv-input-file>
    </div>

    <button
      class="btn btn-primary"
      type="button"
      @click="reset">
      ยกเลิก
    </button>

    <sgv-csv
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-info ml-2">
        ตัวอย่าง CSV
      </button>
    </sgv-csv>

    <div class="mt-3" v-if="existRows.length > 0">
      <span class="text-danger">รหัสควบคุมซ้ำ:</span>
      <sgv-table
        :items="existRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.children"></td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="errorRows.length > 0">
      <span class="text-danger">Error / ไม่พบสินค้า:</span>
      <sgv-table
        :items="errorRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.children"></td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="addRows.length > 0">
      <button
        type="button"
        class="btn btn-success btn-sm mb-2"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
      <sgv-table
        :items="addRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">
              <button
                type="button"
                class="btn btn-success btn-sm mr-1"
                @click="addData(item)">
                เพิ่ม
              </button>
              {{item.code}}
            </td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.children">
              <ol class="mb-0">
                <li v-for="(child,idx) in item.children" :key="idx">
                  {{child.type}}: {{child.inventoryCode}}
                  <div class="text-info">
                    Min: {{child.minQty}} Max: {{child.maxQty}}
                  </div>
                </li>
              </ol>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_IMPORT_VALIDATION,
  CREATE_IMPORT,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `ProcedureInventory${this.$form.capitalize(this.procedureType)}ListImport`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'รายละเอียด', value: 'children'}
      ],
      options: {
        headers: ['code', 'name', 'children'],
        column: null,
        search: null,
        toolbar: null,
      },
      csvFilename: 'ตัวอย่างนำเข้าควบคุมสินค้า1-1',
      csvLabels: {
        code: { title: 'code' },
        name: { title: 'name' },
        type: { title: 'type' },
        inventoryCode: { title: 'inventoryCode' },
        minQty: { title: 'minQty' },
        maxQty: { title: 'maxQty' },
      },
      csvData: [
        {
          code: 'รหัส',
          name: 'ชื่อ',
          type: 'ประเภท',
          inventoryCode: 'รหัสสินค้า',
          minQty: 'จำนวนต่ำสุด',
          maxQty: 'จำนวนสูงสุด',
        },
        {
          code: 'P-A01',
          name: 'ผลิตแอปเปิ้ล',
          type: 'p',
          inventoryCode: 'A01',
          minQty: '100%',
          maxQty: '100%'
        },
        {
          code: '',
          name: '',
          type: 'm',
          inventoryCode: 'C-W101',
          minQty: '100%',
          maxQty: '100%'
        },
        {
          code: '',
          name: '',
          type: 's',
          inventoryCode: 'BOX1',
          minQty: '10%',
          maxQty: '10%'
        },

        {
          code: 'P-B01',
          name: 'ผลิตกล้วย',
          type: 'p',
          inventoryCode: 'B01',
          minQty: '100%',
          maxQty: '100%'
        },
        {
          code: '',
          name: '',
          type: 'm',
          inventoryCode: 'C-W101',
          minQty: '100%',
          maxQty: '100%'
        },
      ],
      items: [],
      rawInputs: []
    }
  },
  computed: {
    existRows () {
      return this.items.filter(v => v.tag === 'exist')
    },
    errorRows () {
      return this.items.filter(v => v.tag.includes('error'))
    },
    addRows () {
      return this.items.filter(v => v.tag === 'add')
    }
  },
  methods: {
    changeToJSON (e) {
      var fileInput = e.target.files[0]
      const options = {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const arr = results.data.slice(1)
          this.rawInputs = this.serializeInput(arr)
          this.getValidation()
        }
      }
      this.$papa.parse(fileInput, options)
    },
    reset () {
      this.setDefault ()
      this.$refs.fileUpload.reset()
    },
    serializeInput (input) {
      const types = [
        {text: 'p', value: 'product'},
        {text: 'm', value: 'material'},
        {text: 's', value: 'scrap'},
      ]

      return input
      .map((v,idx) => v.code ? idx : -1)
      .filter(v => v !== -1)
      .map((start,idx,arr) => {
        const end = idx+1 === arr.length ? input.length : arr[idx+1]
        const formData = {
          code: input[start].code,
          name: input[start].name,
          children: input.slice(start, end).map(child => {
            return {
              type: types.find(t => t.text === child.type)?.value || '',
              inventoryCode: child.inventoryCode,
              minQty: child.minQty,
              maxQty: child.maxQty
            }
          })
        }
        return formData
      })
    },
    getValidation: debounce(function() {
      this.items = []

      this.$apollo.query({
        query: LIST_IMPORT_VALIDATION(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input: this.rawInputs
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(err => {
        this.setDefault ()
        this.$toasted.global.error(err)
      })
    }, 300),
    setDefault() {
      this.items = []
    },
    addData (item) {

      const input = {
        code: item.code,
        name: item.name,
        children: item.children.map(v => {
          return {
            type: v.type,
            inventoryCode: v.inventoryCode,
            minQty: v.minQty,
            maxQty: v.maxQty
          }
        })
      }

      this.$apollo.mutate({
        mutation: CREATE_IMPORT(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    async addAll () {
      const items = this.addRows
      for (let i = 0; i < items.length; i++) {
        await this.addData(items[i])
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
