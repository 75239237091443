<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <Category
            v-model="selectedCategory"
            :procedureType="procedureType"
            :templateType="templateType"
            :options="options">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListImport
      v-if="selectedCategory === -2"
      :templateType="templateType"
      :procedureType="procedureType"
      :selectedCategory="selectedCategory"
      :title="title"
      :group="group">
    </ListImport>

    <ListCategory
      v-else
      :templateType="templateType"
      :procedureType="procedureType"
      :selectedCategory="selectedCategory"
      :title="title"
      :group="group">
    </ListCategory>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import Category from '@/views/procedure_core/Category.vue'
import ListCategory from './ListCategory.vue'
import ListImport from './ListImport.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `${this.templateType}${this.$form.capitalize(this.procedureType)}List`,
      rFields: ['selectedCategory'],
      options: [
        {text: 'นำเข้าข้อมูล', value: -2, role: `procedure:${this.procedureType}:inventory:import`}
      ],
      selectedCategory: 0,
    }
  },
  watch: {
    selectedCategory: 'setRetaining'
  },
  components: {
    Category,
    ListCategory,
    ListImport
  }
}
</script>

<style lang="css" scoped>
</style>
